import React, { useContext } from 'react';

import Box from 'common/src/components/Box';
import Button from 'common/src/components/Button';
import Container from 'common/src/components/UI/Container';
import { DataContext } from 'common/src/contexts/DataContext';
import Drawer from 'common/src/components/Drawer';
import { DrawerContext } from 'common/src/contexts/DrawerContext';
import HamburgMenu from 'common/src/components/HamburgMenu';
import Image from 'common/src/components/Image';
import { LanguageContext } from 'common/src/contexts/LanguageContext';
import Logo from 'common/src/components/UIElements/Logo';
import LogoImage from 'common/src/assets/image/lang4u/lang4ulogo.png';
import LogoImageAlt from 'common/src/assets/image/lang4u/lang4ulogo-alt.png';
import NavbarWrapper from 'common/src/components/Navbar';
import PropTypes from 'prop-types';
import RULang from 'common/src/assets/image/lang4u/russia.svg';
import { RichText } from 'prismic-reactjs';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
import UKLang from 'common/src/assets/image/lang4u/united-kingdom.svg';
import { graphql } from 'gatsby';
import styled from 'styled-components';

export const fragment = graphql`
  fragment NavbarContent on PRISMIC_Landing_page {
    menu {
      label
      path
      scroll_offset
    }
    navigation_cta_label
    navigation_cta_path
    navigation_cta_scroll_offset
  }
`;

const Navbar = ({ navbarStyle, logoStyle, button, row, menuWrapper }) => {
  const { data } = useContext(DataContext);
  const { lang } = useContext(LanguageContext);

  const { state, dispatch } = useContext(DrawerContext);

  if (!data || !data.menu || !data.menu.length) return null;

  const menu = data.menu.map(item => ({
    path: RichText.asText(item.path),
    label: RichText.asText(item.label),
    offset: item.scroll_offset,
  }));

  // Toggle drawer
  const toggleHandler = () => {
    dispatch({
      type: 'TOGGLE',
    });
  };

  return (
    <NavbarWrapper {...navbarStyle} className="saas_navbar">
      <Container>
        <Box {...row}>
          <Logo
            href="/"
            logoSrc={LogoImage}
            title="Lang4U"
            logoStyle={logoStyle}
            className="main-logo"
          />
          <Logo
            href="/"
            logoSrc={LogoImageAlt}
            title="Lang4U"
            logoStyle={logoStyle}
            className="logo-alt"
          />
          <Box {...menuWrapper}>
            <ScrollSpyMenu
              className="main_menu"
              menuItems={menu}
              offset={-70}
            />
            <a
              href={RichText.asText(data.navigation_cta_path)}
              className="navbar_button"
            >
              <Button
                {...button}
                title={RichText.asText(data.navigation_cta_label)}
              />
            </a>
            <Drawer
              width="420px"
              placement="right"
              drawerHandler={<HamburgMenu barColor="#fff" />}
              open={state.isOpen}
              toggleHandler={toggleHandler}
            >
              <ScrollSpyMenu
                className="mobile_menu"
                menuItems={menu}
                drawerClose={true}
                offset={-100}
              />
              {lang === 'en' ? (
                <MobileLangSwitcher href="/ru">
                  <span>На русском</span>
                  <Image
                    src={RULang}
                    alt="Версия на русском языке"
                    width={32}
                    style={{ marginLeft: '16px' }}
                  />
                </MobileLangSwitcher>
              ) : (
                <MobileLangSwitcher href="/">
                  <span>English version</span>
                  <Image
                    src={UKLang}
                    alt="English version"
                    width={32}
                    style={{ marginLeft: '16px' }}
                  />
                </MobileLangSwitcher>
              )}
              <a
                href={RichText.asText(data.navigation_cta_path)}
                className="navbar_drawer_button"
              >
                <Button
                  {...button}
                  title={RichText.asText(data.navigation_cta_label)}
                />
              </a>
            </Drawer>
            {lang === 'en' ? (
              <LangSwitcher href="/ru">
                <Image
                  src={RULang}
                  alt="Версия на русском языке"
                  width={32}
                  style={{ marginLeft: '16px' }}
                />
              </LangSwitcher>
            ) : (
              <LangSwitcher href="/">
                <Image
                  src={UKLang}
                  alt="English version"
                  width={32}
                  style={{ marginLeft: '16px' }}
                />
              </LangSwitcher>
            )}
          </Box>
        </Box>
      </Container>
    </NavbarWrapper>
  );
};

const LangSwitcher = styled.a`
  margin-left: 16px;
  display: none;

  @media (min-width: 991px) {
    display: inline-block;
  }
`;

const MobileLangSwitcher = styled.a`
  margin-top: 18px;
  order: 2;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  color: #343d48;
  font-weight: bold;
  width: 100%;

  span {
    flex-grow: 1;
  }
`;

Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  button: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },
  button: {
    type: 'button',
    fontSize: '13px',
    fontWeight: '700',
    borderRadius: '4px',
    pl: '15px',
    pr: '15px',
    colors: 'secondaryWithBg',
    minHeight: 'auto',
    height: '40px',
  },
  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
