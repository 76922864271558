import { LanguageContext } from 'common/src/contexts/LanguageContext'
import React from 'react';

const ru = [{
  title: 'Lang4U',
  menuItems: [{
    url: '/#education_process',
    text: 'Учебный процесс',
  }, {
    url: '/#pricing',
    text: 'Цены',
  }, {
    url: '/#testimonials',
    text: 'Отзывы',
  }, {
    url: '/#contacts',
    text: 'Контакты',
  }]
}, {
  title: 'Условия',
  menuItems: [{
    url: '/privacy-policy',
    text: 'Политика конфиденциальности',
  }, {
    url: '/refund-policy',
    text: 'Политика возврата средств',
  }, {
    url: '/terms-of-service',
    text: 'Условия предоставления услуг',
  }]
}]

const en = [{
  title: 'Lang4U',
  menuItems: [{
    url: '/en#education_process',
    text: 'Education process',
  }, {
    url: '/en#pricing',
    text: 'Pricing',
  }, {
    url: '/en#testimonials',
    text: 'Testimonails',
  }, {
    url: '/en#contacts',
    text: 'Contacts',
  }]
}, {
  title: 'Terms',
  menuItems: [{
    url: '/en/privacy-policy',
    text: 'Privacy policy',
  }, {
    url: '/en/refund-policy',
    text: 'Money refund policy',
  }, {
    url: '/en/terms-of-service',
    text: 'Terms of service',
  }]
}]

export const useFooterMenuItems = () => {
  const { lang } = React.useContext(LanguageContext)

  if (lang === 'en') {
    return en
  }

  return ru
}
